.dropdown {
  margin-left: 20px;
  position: relative;
  width: 130px;
  filter: url(#goo);
  color: #ffffff;
  font-size: 1em;
  font-weight: 600;
  font-family: "Helvetica", "Arial", sans-serif;

  &__face,
  &__items {
    background-color: #ff0081;
    padding: 20px;
    border-radius: 25px;
  }

  &__face {
    display: block;
    position: relative;
  }

  &__items {
    margin: 0;
    position: absolute;
    right: 0;
    top: 50%;
    width: 100%;
    list-style: none;
    list-style-type: none;
    display: flex;
    justify-content: space-between;
    visibility: hidden;
    z-index: -1;
    opacity: 0;
    transition: all 0.4s cubic-bezier(0.93, 0.88, 0.1, 0.8);

    &::before {
      content: "";
      background-color: #ff0081;
      position: absolute;
      bottom: 100%;
      right: 20%;
      height: 40px;
      width: 20px;
    }
  }

  &__arrow {
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
    position: absolute;
    top: 50%;
    right: 30px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg) translateY(-50%);
    transform-origin: right;
  }

  input {
    display: none;

    &:checked ~ .dropdown__items {
      top: calc(100% + 25px);
      visibility: visible;
      opacity: 1;
    }
  }

  .dropdown__item {
    cursor: pointer;
  }
}

* {
  box-sizing: border-box;
}

svg {
  display: none;
}
